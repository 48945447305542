<script>
  import { FirebaseApp, User, Doc, Collection } from 'sveltefire'
  import Nav from './Components/Nav.svelte'
  import Content from './Components/Content.svelte'

  import firebase from 'firebase/app'
  import 'firebase/firestore'
  import 'firebase/database'
  import 'firebase/auth'
  import 'firebase/performance'
  import 'firebase/analytics'

  const firebaseConfig = {
    apiKey: 'AIzaSyAOHrxGowAlr_lDzYIys1JmiGJv5CmhGl0',
    authDomain: 'whatrichmak-es.firebaseapp.com',
    projectId: 'whatrichmak-es',
    storageBucket: 'whatrichmak-es.appspot.com',
    messagingSenderId: '472730515575',
    appId: '1:472730515575:web:91dbb784dbd15a54f7ee72',
    databaseURL:
      'https://whatrichmak-es-default-rtdb.europe-west1.firebasedatabase.app',
  }

  firebase.initializeApp(firebaseConfig)
</script>

<style global lang="postcss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  button {
    @apply py-1 px-4 rounded-full border-2 border-blue-500 text-lg
        font-medium cursor-pointer hover:-translate-y-1 transition text-blue-600;
  }

  * {
    cursor: none;
  }

  main {
    text-align: center;
    max-width: 240px;
    margin: 0 auto;
  }

  h1,
  em {
    color: #ff3e00;
  }

  hr {
    height: 1px;
    border: none;
    background: rgb(195, 195, 195);
  }

  @media (min-width: 640px) {
    main {
      max-width: none;
    }
  }
</style>

<container>
  <FirebaseApp {firebase}>
    <User let:user let:auth>
      <!-- if checked out -->
      <div
        slot="signed-out"
        class="flex flex-col min-h-screen justify-center items-center
        cursor-default">
        <button on:click={() => auth.signInAnonymously()}>Check-in</button>
      </div>
      <!-- if checked in -->
      <div class="flex flex-col min-h-screen ">
        <Nav />
        <main
          class="flex-grow flex flex-col justify-center items-center w-full ">
          <Content />
        </main>
      </div>
    </User>
  </FirebaseApp>

</container>

<!-- Styles -->
