<script>
  import { getContext, onMount, onDestroy } from 'svelte'

  import { User, Collection } from 'sveltefire'
  import { emojis } from '../lib/emojis'
  import mousePosition from '../lib/mousePosition'

  const app = getContext('firebase').getFirebase()
  const auth = app.auth()
  const db = app.firestore()
  const realtime = app.database()

  let currentUser = auth.currentUser.uid

  function ranT(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  function stringToIntHash(str, upperbound, lowerbound) {
    let result = 0
    for (let i = 0; i < str.length; i++) {
      result = result + str.charCodeAt(i)
    }
    return (result % (upperbound - lowerbound)) + lowerbound
  }

  let sendMousePosition = () => {
    if ($mousePosition.x % 3 === 0 && $mousePosition.y % 3 === 0) {
      db.collection('activeUsers')
        .doc(auth.currentUser.uid)
        .set({
          hex: auth.currentUser.uid,
          xPos: $mousePosition.x,
          yPos: $mousePosition.y,
        })
        .then(() => {})
        .catch((e) => {
          console.error(e)
        })
      // realtime db
      // realtime.ref('activeusers/' + auth.currentUser.uid).set({
      //   uuid: auth.currentUser.uid,
      //   xPos: $mousePosition.x,
      //   yPos: $mousePosition.y,
      // })
    }
  }

  let mousePositions = () => {
    realtime.ref('activeusers').on('value', (snapshot) => {
      return snapshot.val()
    })
  }

  $: $mousePosition, sendMousePosition()

  onDestroy(() => {
    db.collection('activeUsers')
      .doc(currentUser)
      .delete()
      .then(() => {
        console.log('Document successfully deleted!')
      })
      .catch((error) => {
        console.error('Error removing document: ', error)
      })
  })
</script>

<User let:user let:auth>
  <nav class=" bg-gray-50">
    <div class="container mx-auto flex justify-between p-4">

      <span class="text-3xl">
        {emojis[stringToIntHash(user.uid, emojis.length - 1, 0)]}
      </span>

      <button
        class="border-red-400 text-red-500 py-1 text-sm border-none bg-white
        opacity-80 hover:opacity-100"
        on:click={() => auth.signOut()}>
        Check out →
      </button>

    </div>
  </nav>

  <div
    class="fixed pointer-events-none text-3xl"
    style="transform: translateX({$mousePosition.x - 16}px) translateY({$mousePosition.y - 20}px);">
    {emojis[stringToIntHash(user.uid, emojis.length - 1, 0)]}
  </div>
  <!-- {#if mousePositions()}
    {#each mousePositions() as otherUser}
      {console.log(otherUser)}
      {#if otherUser.uuid != user.uid}
        <div
          class="fixed pointer-events-none text-3xl"
          style="left:{otherUser.xPos - 16}px; top:{otherUser.yPos - 20}px; ">
          {emojis[stringToIntHash(otherUser.id, emojis.length - 1, 0)]}
        </div>
      {/if}
    {/each}
  {/if} -->

  <Collection path={'activeUsers'} let:data={activeUsers}>
    {#each activeUsers as otherUser}
      {#if otherUser.id != user.uid}
        <div
          class="fixed pointer-events-none text-3xl transition duration-100"
          style="transform: translateX({otherUser.xPos - 16}px) translateY({otherUser.yPos - 20}px);
          ">
          {emojis[stringToIntHash(otherUser.id, emojis.length - 1, 0)]}
        </div>
      {/if}
    {/each}
  </Collection>

</User>
