<script>
  import { getContext } from 'svelte'
  import * as content from '../lib/content.json'
  import { Doc } from 'sveltefire'

  const app = getContext('firebase').getFirebase()
  const auth = app.auth()
  const db = app.firestore()

  let activeProject = 0
</script>

<style>
  iframe {
    height: calc(80vh - 4rem);
  }
  .arrow-button {
    @apply p-2 px-4 text-2xl cursor-pointer hover:opacity-60 transition;
  }
</style>

<nav class=" w-full">

  <Doc path={`controller/main`} let:data={controller} let:ref={controllerRef}>

    <div
      class="container mx-auto flex flex-col justify-between border
      border-gray-400 rounded-lg max-h-full overflow-hidden">
      <div class="flex justify-between w-full p-4 px-6 bg-gray-50">
        <h2 class="text-lg">{content.projects[controller.activePage].title}</h2>
        <a href={content.projects[controller.activePage].link}>
          <span>Link ↗</span>
        </a>
      </div>
      <iframe
        class="flex-grow"
        src={content.projects[controller.activePage].link}
        frameborder="0"
        title={content.projects[controller.activePage].title} />
    </div>
    <!-- controls -->
    <div class="flex justify-between container mx-auto mt-4 px-2 select-none">
      <div
        class="arrow-button"
        on:click={() => {
          if (controller.activePage > 0) {
            controllerRef.set({ activePage: (controller.activePage -= 1) })
          } else {
            console.log('wrong way m80 👈👀👈')
          }
        }}>
        {#if controller.activePage > 0}←{/if}
      </div>
      <div
        class="arrow-button"
        on:click={() => {
          if (controller.activePage < content.projects.length - 1) {
            activeProject += 1
            controllerRef.set({ activePage: (controller.activePage += 1) })
          } else {
            console.log('no more 👉😎👉')
          }
        }}>
        {#if controller.activePage < content.projects.length - 1}→{/if}
      </div>
    </div>
  </Doc>

</nav>
